/* Container for the video */
.video-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Full height of the viewport */
    overflow: hidden;
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
  }
    
  /* Fullscreen video with scaling and positioning */
  .background-video {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(1.1); /* Center the video horizontally */
    min-width: 100vw; /* Ensures video covers the entire width */
    min-height: 100vh; /* Ensures video covers the entire height */
    filter: blur(4px); /* Blur effect */
    object-fit: cover; /* Scale the video to fit the container */
    z-index: -1; /* Ensures video stays behind the content */
  }
  