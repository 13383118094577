.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.introduction {
  text-align: justify;
}

/* Styling the text */
h1 {
  font-size: 3em;
  margin: 0;
}

h2 {
  font-size: 2em;
  margin-top: 20px;
}

h3 {
  font-size: 1.5em;
}


p {
  margin-top: 20px;
}

li {
  margin-top: 1px;
}


/* Content container with a blurred background */
.content {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  z-index: 1; /* Keeps content above the video */
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 40px; /* Increased padding for more space inside the box */
  width: 90%; /* Adjust the width as needed */
  max-width: 1600px; /* Maximum width of the box */
  box-sizing: border-box; /* Ensure padding is included in the height */
  border-radius: 15px; /* Rounded corners */
  background: rgba(255, 255, 255, 0.49); /* Semi-transparent white background */
  backdrop-filter: blur(10px); /* Apply a background blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: border for better visibility */
}

/* Shared style for each country section */
.table-of-countries {
  margin-top: 30px;
  padding: 20px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  color: black;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background for the entire box */

}

/* Shared style for each country section */
.country-section {
  margin-top: 30px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  box-sizing: border-box;
  backdrop-filter: blur(10px);
  color: black;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background for the entire box */

}

/* United Kingdom flag-inspired background */
.bg-UK {
  background: linear-gradient(
    135deg,
    rgba(0, 33, 71, 0.39) 25%,
    /* rgba(255, 255, 255, 0.39) 25%, */
    rgba(206, 17, 38, 0.39) 33%,
    rgba(255, 255, 255, 0.39) 50%,
    /* rgba(206, 17, 38, 0.39) 50%, */
    rgba(206, 17, 38, 0.39) 75%,
    rgba(0, 33, 71, 0.39) 85%
  );
  /* background: linear-gradient(135deg, blue, blue 20%, red 40%, white 55%, red 70%, blue 90%); */
  background-size: cover;
}

/* Portugal flag-inspired background */
.bg-PT {
  background: linear-gradient(
    90deg,
    rgba(0, 102, 51, 0.39) 30%,
    rgba(238, 210, 2, 0.39) 40%,
    rgba(206, 17, 38, 0.39) 60%
  );
  background-size: cover;
}

/* Canada flag-inspired background */
.bg-CA {
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 0, 0.39) 25%, /* Red */
    rgba(255, 255, 255, 0.39) 33%, /* White */
    rgba(255, 0, 0, 0.39) 50%, /* Red */
    rgba(255, 255, 255, 0.39) 66%, /* White */
    rgba(255, 0, 0, 0.39) 75% /* Red */
  );
}

/* Spain flag-inspired background */
.bg-ES {
  background-image: linear-gradient(
    180deg,
    rgba(215, 12, 12, 0.39) 15%, /* Red */
    rgba(255, 204, 0, 0.39) 50%,  /* Yellow */
    rgba(215, 12, 12, 0.39) 85%   /* Red */
  );
}

/* Switzerland flag-inspired background */
.bg-CH {
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 0, 0.39) 15%, 
    rgba(255, 255, 255, 0.39) 50%,
    rgba(255, 0, 0, 0.39) 85%
  );
}

/* France flag-inspired background */
.bg-FR {
  background-image: linear-gradient(
    90deg,
    rgba(0, 85, 164, 0.39) 33.33%, /* Blue */
    rgba(255, 255, 255, 0.39) 66.66%, /* White */
    rgba(239, 65, 53, 0.39) 99.99% /* Red */
  );
}

/* Czech Republic flag-inspired background */
.bg-CZ {
  background-image: linear-gradient(
    135deg,
    rgba(0, 43, 127, 0.39) 30%, /* Blue (diagonal) */
    rgba(255, 255, 255, 0.39) 60%, /* White */
    rgba(206, 17, 38, 0.39) 85% /* Red */
  );
}

/* Austria flag-inspired background */
.bg-AT {
  background-image: linear-gradient(
    180deg,
    rgba(215, 12, 12, 0.39) 15%, /* Red */
    rgba(255, 255, 255, 0.39) 50%,  /* Yellow */
    rgba(215, 12, 12, 0.39) 85%   /* Red */
  );
}

/* Belgium flag-inspired background */
.bg-BE {
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.39) 33.33%, /* Black */
    rgba(255, 223, 0, 0.39) 66.66%, /* Yellow */
    rgba(239, 51, 64, 0.39) 99.99% /* Red */
  );
}

/* Norway flag-inspired background */
.bg-NO {
  background-image: linear-gradient(
    135deg,
    rgba(186, 12, 47, 0.39) 25%, /* Red */
    rgba(255, 255, 255, 0.39) 30%, /* White */
    rgba(0, 32, 91, 0.39) 40%, /* Blue */
    rgba(186, 12, 47, 0.39) 70% /* Red */
  );
}

/* Australia flag-inspired background */
.bg-AU {
  background-image: linear-gradient(
    135deg,
    rgba(0, 33, 122, 0.39) 30%, /* Dark Blue */
    rgba(255, 255, 255, 0.39) 45%, /* White */
    rgba(255, 0, 0, 0.39) 60% /* Red */
  );
}

/* Albania flag-inspired background */
.bg-AL {
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 0, 0.39) 33.33%, /* Black */
    rgba(0, 0, 0, 0.39) 50.66%, /* Red */
    rgba(255, 0, 0, 0.39) 99.99% /* Black */
  );
}

/* Cabo Verde flag-inspired background */
.bg-CV {
  background-image: linear-gradient(
    180deg,
    rgba(0, 56, 168, 0.39) 60%, /* Blue */
    rgba(255, 255, 255, 0.39) 65%, /* White */
    rgba(210, 16, 52, 0.39) 85%, /* Red */
    rgba(255, 255, 255, 0.39) 90% /* White */
  );
}

/* Mexico flag-inspired background */
.bg-MX {
  background-image: linear-gradient(
    90deg,
    rgba(0, 104, 71, 0.39) 33.33%, /* Green */
    rgba(255, 255, 255, 0.39) 66.66%, /* White */
    rgba(206, 17, 38, 0.39) 99.99% /* Red */
  );
}

/* China flag-inspired background */
.bg-CN {
  background-image: linear-gradient(
    90deg,
    rgba(255, 223, 0, 0.39) 5.33%, /* Yellow */
    rgba(239, 51, 64, 0.39) 25.99% /* Red */
  );
}

/* Sri Lanka flag-inspired background */
.bg-LK {
  background-image: linear-gradient(
    90deg,
    rgba(0, 122, 61, 0.39) 25%, /* Green */
    rgba(252, 177, 49, 0.39) 40%, /* Yellow */
    rgba(138, 21, 56, 0.39) 65% /* Dark Red */
  );
}

/* UAE flag-inspired background */
.bg-AE {
  background-image: linear-gradient(
    270deg,
    rgba(0, 115, 47, 0.39) 25%, /* Green */
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(0, 0, 0, 0.39) 75%, /* Black */
    rgba(206, 17, 38, 0.39) 10% /* Red (vertical bar) */
  );
}

/* Philippines flag-inspired background */
.bg-PH {
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.39) 30%, /* Blue (diagonal) */
    rgba(0, 43, 127, 0.39) 60%, /* White */
    rgba(206, 17, 38, 0.39) 85% /* Red */
  );
}

/* Gibraltar flag-inspired background */
.bg-GI {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(206, 17, 38, 0.39) 100% /* Red */
  );
}

/* Japan flag-inspired background */
.bg-JP {
  background-image: radial-gradient(
    circle,
    rgba(206, 17, 38, 0.39) 20%, /* Red circle */
    rgba(255, 255, 255, 0.39) 20% /* White background */
  );
}

/* Germany flag-inspired background */
.bg-DE {
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.39) 33.33%, /* Black */
    rgba(221, 0, 0, 0.39) 66.66%, /* Red */
    rgba(255, 206, 0, 0.39) 99.99% /* Yellow */
  );
}

/* South Africa flag-inspired background */
.bg-ZA {
  background-image: linear-gradient(
    45deg,
    rgba(0, 122, 61, 0.39) 20%, /* Green */
    rgba(252, 177, 49, 0.39) 30%, /* Yellow */
    rgba(206, 17, 38, 0.39) 50%, /* Red */
    rgba(0, 32, 91, 0.39) 70%, /* Blue */
    rgba(0, 0, 0, 0.39) 100% /* Black */
  );
}


/* Iceland flag-inspired background */
.bg-IS {
  background-image: linear-gradient(
    90deg,
    rgba(0, 32, 91, 0.39) 40%, /* Blue */
    rgba(255, 255, 255, 0.39) 45%, /* White cross */
    rgba(220, 30, 53, 0.39) 50%, /* Red cross */
    rgba(255, 255, 255, 0.39) 55%, /* White cross */
    rgba(0, 32, 91, 0.39) 60% /* Blue */
  );
}

/* Denmark flag-inspired background */
.bg-DK {
  background-image: linear-gradient(
    90deg,
    rgba(198, 12, 48, 0.39) 40%, /* Red */
    rgba(255, 255, 255, 0.39) 45%, /* White cross */
    rgba(198, 12, 48, 0.39) 50%, /* Red */
    rgba(255, 255, 255, 0.39) 55%, /* White cross */
    rgba(198, 12, 48, 0.39) 60% /* Red */
  );
}


/* Oman flag-inspired background */
.bg-OM {
  background-image: linear-gradient(
    90deg,
    rgba(206, 17, 38, 0.39) 33%, /* Red */
    rgba(255, 255, 255, 0.39) 66%, /* White */
    rgba(0, 122, 61, 0.39) 100% /* Green */
  );
}

/* South Korea flag-inspired background */
.bg-KR {
  background-image: radial-gradient(
    circle,
    rgba(206, 17, 38, 0.39) 20%, /* Red part of circle */
    rgba(0, 32, 91, 0.39) 40%, /* Blue part of circle */
    rgba(255, 255, 255, 0.39) 40% /* White background */
  );
}

/* Ecuador flag-inspired background */
.bg-EC {
  background-image: linear-gradient(
    180deg,
    rgba(255, 206, 0, 0.39) 50%, /* Yellow */
    rgba(0, 56, 168, 0.39) 75%, /* Blue */
    rgba(206, 17, 38, 0.39) 100% /* Red */
  );
}

/* USA flag-inspired background */
.bg-US {
  background-image: repeating-linear-gradient(
    180deg,
    rgba(178, 34, 52, 0.39) 0%, /* Red */
    rgba(178, 34, 52, 0.39) 20%, 
    rgba(255, 255, 255, 0.39) 20%, /* White */
    rgba(255, 255, 255, 0.39) 40%
  ),
  linear-gradient(
    90deg,
    rgba(10, 49, 97, 0.39) 0%, /* Blue canton */
    rgba(10, 49, 97, 0.39) 40%,
    rgba(0, 0, 0, 0) 40% /* Transparent to align with stripes */
  );
}

/* Argentina flag-inspired background */
.bg-AR {
  background-image: linear-gradient(
    180deg,
    rgba(116, 172, 223, 0.39) 25%, /* Light Blue */
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(116, 172, 223, 0.39) 100% /* Light Blue */
  );
}

/* Italy flag-inspired background */
.bg-IT {
  background-image: linear-gradient(
    90deg,
    rgba(0, 146, 70, 0.39) 25%, /* Green */
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(206, 17, 38, 0.39) 100% /* Red */
  );
}

/* Vatican flag-inspired background */
.bg-VA {
  background-image: linear-gradient(
    90deg,
    rgba(252, 209, 22, 0.39) 50%, /* Yellow */
    rgba(255, 255, 255, 0.39) 50% /* White */
  );
}

/* Turkey flag-inspired background */
.bg-TR {
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 0, 0.39) 8%, 
    rgba(255, 255, 255, 0.39) 15%,
    rgba(255, 0, 0, 0.39) 85%
  );
}

/* Singapore flag-inspired background */
.bg-SG {
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.39) 8%,
    rgba(255, 0, 0, 0.39) 15%, 
    rgba(255, 255, 255, 0.39) 85%
  );
}

/* Croatia flag-inspired background */
.bg-HR {
  background-image: linear-gradient(
    180deg,
    rgba(206, 17, 38, 0.39) 33.33%, /* Red */
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(0, 56, 168, 0.39) 66.66% /* Blue */
  );
}

/* Brazil flag-inspired background */
.bg-BR {
  background-image: linear-gradient(
    90deg,
    rgba(0, 156, 59, 0.39) 15%, /* Green */
    rgba(255, 204, 0, 0.39) 35%, /* Yellow */
    rgba(0, 0, 255, 0.39) 50%, /* Blue */
    rgba(255, 204, 0, 0.39) 65%, /* Yellow */
    rgba(0, 156, 59, 0.39) 85% /* Green */
    );
  }
  
/* Hungary flag-inspired background */
.bg-HU {
  background-image: linear-gradient(
    180deg,
    rgba(206, 17, 38, 0.39) 33.33%, /* Red */
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(0, 122, 61, 0.39) 66.66% /* Green */
  );
}

/* Poland flag-inspired background */
.bg-PL {
  background-image: linear-gradient(
    0deg,
    rgba(255, 0, 0, 0.39) 50%, /* Red */
    rgba(255, 255, 255, 0.39) 50% /* White */
    );
}
  
/* Netherlands flag-inspired background */
.bg-NL {
  background-image: linear-gradient(
    180deg,
    rgba(179, 0, 0, 0.39) 33.33%, /* Red */
    rgba(255, 255, 255, 0.39) 50%, /* White */
    rgba(0, 32, 91, 0.39) 66.66% /* Blue */
  );
}


/* Monaco flag-inspired background */
.bg-MC {
  background-image: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0.39) 50%, /* Red */
    rgba(255, 255, 255, 0.39) 50% /* White */
  );
}